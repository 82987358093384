import React from "react"
import { connect } from "react-redux"
import { Container, Nav, Navbar, NavbarBrand, NavItem, NavLink, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { Avatar } from "@material-ui/core"

class HeaderComp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  render() {
    const { loggedIn, user } = this.props

    return (
      <Navbar color="dark" expand="dark">
        <Container>
          <NavbarBrand href="/"><h2 className="text-light">The Games</h2></NavbarBrand>
            <Nav className="ml-auto mr-0" navbar>
              {loggedIn ?
                <>
                  {!user ?
                    <p>Loading...</p>
                    :
                    <Dropdown isOpen={this.state.isOpen} toggle={e => this.setState({ isOpen: !this.state.isOpen })}>
                      <DropdownToggle tag="div" carat>
                        <Avatar 
                          src={user.avatar_url ? `${user.avatar_url}` : ""}
                        >
                          {`${user.username.charAt(0)}`}
                        </Avatar>
                      </DropdownToggle>
                      <DropdownMenu className="bg-dark">
                        <DropdownItem header className="text-light">{user.username}</DropdownItem>
                        <DropdownItem divider className="bg-dark" />
                        <DropdownItem className="bg-dark"><NavLink className="text-light" href="/dashboard">Dashboard</NavLink></DropdownItem>
                        <DropdownItem className="bg-dark"><NavLink className="text-light" href="/account">Account</NavLink></DropdownItem>
                        <DropdownItem className="bg-dark"><NavLink className="text-light" href="/settings">Game Settings</NavLink></DropdownItem>
                        <DropdownItem className="bg-dark"><NavLink className="text-light" href="/logout">Logout</NavLink></DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  }
                </>
              :
              <NavItem>
                <NavLink className="text-light" href="/login">Login</NavLink>
              </NavItem>
            }
          </Nav>
        </Container>
      </Navbar>
    )
  }
}

const mapStateToProps = state => {
  const { loggedIn, user } = state.auth

  return { loggedIn, user }
}

const Header = connect(mapStateToProps)(HeaderComp)

export { Header }

export default Header