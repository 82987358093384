import React, { useState } from "react"
import classnames from "classnames"
import { Table, Container, Row, Col, Button, Card, TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, FormText, Label, Input } from "reactstrap"

import { request } from "../../api"

import { Layout } from "../../components"
import { convertSecToHMS } from "../../helpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"

const Factions = () => {
  const [state, setState] = useState({
    data: null,
    loading: false,
    error: null,
    email: "",
    fieldsEmpty: true,
    sameEmail: false,
    verifiedEmail: false,
    verifyData: null
  })

  if(!state.data && !state.loading && !state.error) {
    setState({ ...state, loading: true, data: null })
    
    request('/auth/email', {}, "GET", true)
    .then(res => setState({ ...state, loading: false, data: res.data }))
    .catch(err => setState({ ...state, loading: false, error: err }))
  }

  const updateField = (field, value) => {
    var newState = { ...state }

    newState[field] = value

    newState.fieldsEmpty = newState.email === ""
    newState.sameEmail = newState.email === newState.data?.email

    setState({ ...newState })
  }

  console.log(state)

  return (
    <Container className="mt-3">
      <Row className="d-flex">
        <Col md="8" className="ml-auto mr-auto">

        </Col>
      </Row>
    </Container>
  )
}

const GameSettings = props => {
  const [state, setState] = useState({
    activeTab: 'settings-0'
  })

  const setTab = tab => {
    setState({ ...state, activeTab: tab })
  }

  return (
    <Layout>
      <Container>
        <Row className="d-flex">
          <Col md="10" className="ml-auto mr-auto mt-3">
            <h1 className="w-100 text-center">Settings</h1>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: state.activeTab === 'settings-0' })}
                  onClick={() => { setTab('settings-0') }}
                >
                  Factions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: state.activeTab === 'settings-?' })}
                  onClick={() => { setTab('settings-?') }}
                >
                  Coming Soon...
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId="settings-0">
                <Factions />
              </TabPane>
              <TabPane tabId="settings-?">
                <Row>
                  <Col sm="12">
                    <h4>New Tab Contents</h4>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export { GameSettings }

export default GameSettings